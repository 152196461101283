
					@import './src/sass/variables';
					@import './src/sass/mixins';
				



















































.dropzone::v-deep {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	.file-upload {
		opacity: 0.5;

		&:hover {
			opacity: 0.85;
			backdrop-filter: blur(3px);
		}
	}
}

.block-inner {
	display: flex;
	flex-flow: row nowrap;

	&.align-right {
		justify-content: flex-end;
	}

	&.align-center {
		justify-content: center;
	}
}
